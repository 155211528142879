<template>
  <div>
    <ca-header
      heading="Wiedervorlagen"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-primary"
          :to="{name: 'ResubmitCreate' }"
        >
          Neue Wiedervorlage erstellen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <b-tabs class="tabs">
        <b-tab
          title="Aktuell"
          lazy
        >
          <data-table
            :fields="fields"
            card-as-tab
            service="resubmission"
            :base-query="baseQuery()"
            :search-field="false"
            @row-clicked="(item) => $router.push({name: 'ResubmitDetail', params: {resubmitId: item._id}})"
          >
            <template #customSearch>
              <div class="row align-items-center">
                <div class="col-auto font-weight-bold">
                  Suche:
                </div>
                <BasicInput
                  v-model="searchOrderNumber"
                  placeholder="Vertragsnummer"
                  :margin="false"
                  type="search"
                  class="col"
                />
                <BasicInput
                  v-model="searchCustomer"
                  placeholder="Kunde"
                  :margin="false"
                  type="search"
                  class="col"
                />
                <BasicInput
                  v-model="searchSubject"
                  placeholder="Betreff"
                  :margin="false"
                  type="search"
                  class="col"
                />
                <BasicInput
                  v-model="searchCategory"
                  placeholder="Kategorie"
                  :margin="false"
                  type="search"
                  class="col"
                />
                <BasicInput
                  v-model="searchStatus"
                  placeholder="Status"
                  :margin="false"
                  type="search"
                  class="col"
                />
                <BasicInput
                  v-model="searchBearbeiter"
                  placeholder="Bearbeiter"
                  :margin="false"
                  type="search"
                  class="col"
                />
              </div>
            </template>
            <template
              slot="orderNumber"
              slot-scope="{item: data}"
            >
              <router-link
                v-if="data.orderId"
                :to="{name: 'HSSOrderDetail', params: {orderId: data.orderId} }"
                target="_blank"
                @click.stop
              >
                {{ data.orderNumber }}
                <i class="fas fa-external-link" />
              </router-link>
            </template>
            <template
              slot="customer"
              slot-scope="{value: customer, unformatted: data}"
            >
              <router-link
                v-if="data._id"
                :to="{name: 'CustomerDetail', params: {customerId: data._id} }"
                target="_blank"
                @click.stop
              >
                {{ customer }}
                <i class="fas fa-external-link" />
              </router-link>
            </template>
            <template
              slot="subject"
              slot-scope="{item: data}"
            >
              <router-link
                v-if="data._id"
                :to="{name: 'ResubmitDetail', params: {resubmitId: data._id} }"
                target="_blank"
                @click.stop
              >
                {{ data.subject }}
                <i class="fas fa-external-link" />
              </router-link>
            </template>
          </data-table>
        </b-tab>
        <b-tab
          title="Geplant"
          lazy
        >
          <data-table
            :fields="fields"
            card-as-tab
            service="resubmission"
            :base-query="futureQuery()"
            :search-field="false"
            @row-clicked="(item) => $router.push({name: 'ResubmitDetail', params: {resubmitId: item._id}})"
          >
            <template
              slot="status"
              slot-scope="{value: status, unformatted: statusCode}"
            >
              <span
                class="badge"
                :class="getResubmitStatusClass(statusCode)"
              >{{ status }}</span>
            </template>
            <template #customSearch>
              <CustomSearch
                @updateSearchQuery="futureSearchQuery = $event"
              />
            </template>
          </data-table>
        </b-tab>
        <b-tab
          v-if="me.role === 10"
          title="Alle"
          lazy
        >
          <data-table
            :fields="[
              {
                label: 'Bearbeiter',
                key: 'user'
              },
              ...fields
            ]"
            card-as-tab
            service="resubmission"
            :base-query="{}"
            @row-clicked="(item) => $router.push({name: 'ResubmitDetail', params: {resubmitId: item._id}})"
          >
            <template
              slot="status"
              slot-scope="{value: status, unformatted: statusCode}"
            >
              <span
                class="badge"
                :class="getResubmitStatusClass(statusCode)"
              >{{ status }}</span>
            </template>
          </data-table>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatDate from '@/filters/formatDate.js'
import resubmitStatus from '@/resources/enums/resubmitStatus'
import getResubmitStatusClass from '@/helpers/getResubmitStatusClass.js'
import moment from 'moment'
import hsOrderStatus from '@/resources/enums/hsOrderStatus'
import resubmitCategory from '@/resources/enums/resubmitCategory'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import CustomSearch from './components/CustomSearch.vue'

export default {
  components: {
    DataTable,
    CaHeader,
    BasicInput,
    CustomSearch
  },
  data () {
    return {
      resubmitStatus,
      getResubmitStatusClass,
      searchOrderNumber: null,
      searchCustomer: null,
      searchSubject: null,
      searchCategory: null,
      searchStatus: null,
      searchBearbeiter: null,
      futureSearchQuery: null
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    fields () {
      const fields = [
        {
          label: 'Vertragsnummer',
          key: 'orderNumber',
          sortable: true
        },
        {
          label: 'Kunde',
          key: 'customer',
          formatter: customer => {
            if (!customer) return
            return `${customer.name.VOR ? customer.name.VOR : ''} ${customer.name.NAM}`
          },
          sortable: true
        },
        {
          label: 'Betreff',
          key: 'subject',
          sortable: true
        },
        {
          label: 'Kategorie',
          key: 'category',
          formatter: (category) => {
            if (!category || !resubmitCategory[category]) return ''
            return resubmitCategory[category].human
          },
          sortable: true
        },
        {
          label: 'Status',
          key: 'order.hsOrderStatus',
          formatter: (status) => {
            if (!status) return ''
            return hsOrderStatus[status].human
          },
          sortable: true
        },
        {
          label: 'Erstellt am',
          key: 'createdAt',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Fällig am',
          key: 'dueDate',
          formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
          sortable: true
        },
        {
          label: 'Mitarbeiter',
          key: 'bearbeiter',
          formatter: bearbeiter => {
            if (!bearbeiter) return
            return `${bearbeiter.firstname} ${bearbeiter.lastname}`
          },
          sortable: true
        }
      ]
      return fields
    }
  },
  methods: {
    todayQuery () {
      return {
        dueDate: { $lte: moment().endOf('day').toDate() }
      }
    },
    futureQuery () {
      return {
        ...this.futureSearchQuery,
        dueDate: { $gt: moment().endOf('day').toDate() }
      }
    },
    baseQuery () {
      const query = { $client: { $lookup: {} } }
      // Felder in lookup sind nur als Fremdschlüssel vorhanden
      if (this.searchCustomer) {
        query.$client.$lookup.customerName = this.searchCustomer
      }
      if (this.searchBearbeiter) {
        query.$client.$lookup.bearbeiterName = this.searchBearbeiter
      }
      if (this.searchStatus) {
        query.$client.$lookup.orderStatus = this.searchStatus
      }
      // normal query
      if (this.searchOrderNumber) {
        query.orderNumber = { $regex: this.searchOrderNumber, $options: 'i' }
      }
      if (this.searchSubject) {
        query.subject = { $regex: this.searchSubject, $options: 'i' }
      }
      if (this.searchCategory) {
        query.category = { $regex: this.searchCategory, $options: 'i' }
      }
      return query
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs ::v-deep{
  .tab-pane:focus {
    outline: none;
  }
}
</style>
